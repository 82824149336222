import React from "react"
import logo from "../images/logos/ASPN_Logo_Stacked_Gradient.png"
import LatestRelease from "./latestRelease"

const Content = () => (
  <div className="fullContainer">

    {/* <div className="midContianer contentSection"> */}
    <div className="midContianer">
      <div style={{maxWidth: "500px", alignSelf: "center"}}>
        <img src={logo} alt="aspn" />
      </div>
      <div className="login">
        <h3>Access ASPN</h3>
        <div>
          <form action="https://portal.pages.aspn.us/">
            <button className="formBtn">Login</button>
          </form>
        </div>
        <div style={{padding: "1rem"}}>
          <h4>Don't have an account?</h4>
          <p style={{marginBottom: "0"}}>
            Begin the process to request access <a href="./devAccess">here.</a>
          </p>
        </div>
      </div>
    </div>
    <div className="contentBox">
      <p className="content">The last several years has seen an emphasis on the development of numerous complementary
  Positioning, Navigation, and Timing (PNT) approaches to provide resilience to current and
  emerging threats to the Global Positioning System (GPS). The integration of these approaches
  into new and existing systems has been challenging due to the diversity of Department of
  Defense (DoD) missions, platforms, and interfaces. Within PNT, there is growing interest to
  employ Modular Open System Approaches (MOSA) in development activities in order to
  address this challenge, thereby enabling faster technology refresh, cost savings and avoidance,
  and increased interoperability. Utilizing modular design principles and open standards enables
  PNT systems with architectural flexibility allowing new capabilities to be more rapidly
  integrated and deployed.<br /><br />
  The Office of the Under Secretary of Defense (OUSD), Research and Engineering (R&E) has
  initiated an effort to develop MOSA implementations for PNT. The goal is to enable consistent
  acquisitions and interoperability across PNT programs by providing a common data standard for
  PNT systems. The data standard use the Defense Advanced Research Projects Agency (DARPA)
  interface control documents created for the All Source Position and Navigation (ASPN)
  program. Many companies and projects continue to implement and use ASPN, though some
  problems have arisen in the years since the last major ASPN update.
      </p>
    </div>
    <LatestRelease />
  </div>
)

export default Content
