import React from "react"

import "../styles/button.css"

const Button = ({ children }) => (
  <button
    className="contactBtn"
  >
    {children}
  </button>
)

export default Button
