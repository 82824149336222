import React from "react"
import logo from "../images/logos/ASPN_Logo_A_Primary.png"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/latestRelease.css"

const LatestRelease = () => {
  const data = useStaticQuery(graphql`
    query {
      file(ext: {eq: ".zip"}) {
        publicURL
      }
    }
  `)
  return(
      <div className="releaseOuterContainer">
        <div className="releaseContainer">
            <div className="releaseIcon">
                <img src={logo} alt="ASPN Logo" />
            </div>
            <div className="releaseTextContainer">
                <div className="releaseText">
                    <h4>ASPN 2023</h4>
                    <p>ASPN 2023 is released as a community-derived improvement over ASPN 3.0. The naming convention of ASPN 2023 was hanged to reflect the release year and avoids the semantic versioning approach.</p>
                </div>
                <a className="download" download href={data.file.publicURL}>Download</a>
            </div>
        </div>
      </div>
    )
  }

export default LatestRelease
