import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import CallToAction from "../components/cta"

const IndexPage = () => (
  <Layout>
    <SEO title="aspn.us" />
    <div style={{margin: "auto", display:"flex", flexDirection:"column"}}>
      <Content />
      <CallToAction />
    </div>
  </Layout>
)

export default IndexPage
