import React from "react"

import Button from "../components/button"

const CallToAction = () => (
  <div
    style={{padding: "2rem", textAlign: "center"}}>
    <h2 className="cta">Looking for more info?</h2>
    <form action="mailto:info@aspn.us">
      <Button>Contact Us</Button> or e-mail info@aspn.us
    </form>
  </div>
)

export default CallToAction
